 // Initialize Firebase
 import firebase from 'firebase'
 require('firebase/firestore')
 
 var config = {
  apiKey: "AIzaSyBy2AxX38rJdgSx5rC_rx3LI_MfpodG4SI",
  authDomain: "greatnet-76b2b.firebaseapp.com",
  databaseURL: "https://greatnet-76b2b.firebaseio.com",
  projectId: "greatnet-76b2b",
  storageBucket: "gs://greatnet-76b2b.appspot.com",
  messagingSenderId: "362419726894"
};
  var firebase_init=firebase.initializeApp(config);
  export const Authprovider = new firebase.auth.GoogleAuthProvider();
  export const auth = firebase.auth();
  export const firestore=firebase.firestore();
  export const database=firebase.database();
  export const storageRef = firebase.storage().ref();
  export default firebase_init;