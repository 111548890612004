import React, { Component } from 'react'
import { Tag, Carousel } from 'antd';

const CheckableTag = Tag.CheckableTag;

const tagsFromServer = {};
tagsFromServer['Home'] = [];
tagsFromServer['Net'] = [];
tagsFromServer['NetWeb'] = ['Đa dạng','Đưa tin', 'Hỏi đáp', 'Học tập', 'Cửa hàng', 'Sản phẩm', 'Quảng cáo'];
tagsFromServer['NetYou'] = ['Bộ lọc', 'Cuộc sống', 'Học hỏi', 'Tôn giáo', 'Triết học', 'Câu đố', 'Sáng tạo', 'Thêm'];
tagsFromServer['NetMe'] = ['Văn chương', 'Ngoại ngữ', 'Từ điển', 'Sách', 'Lớp học'];
tagsFromServer['NetWe'] = ['Bộ lọc', 'Hình ảnh', 'Video', 'Hình 360', 'Video 360', 'Giới thiệu', 'Thông tin'];
tagsFromServer['NetFilm'] = ['Bộ lọc', 'Hình ảnh', 'Giới thiệu', 'Thông tin'];
tagsFromServer['MusicNet'] = ['Bộ lọc', 'Hình ảnh', 'Video', 'Giới thiệu', 'Thông tin'];
tagsFromServer['NetJob'] = ['Bộ lọc', 'Hình ảnh', 'Video', 'Giới thiệu', 'Thông tin'];

tagsFromServer['NetLand'] = ['Bộ lọc', 'Hình ảnh', 'Video', 'Hình 360', 'Video 360', 'Giới thiệu'];
tagsFromServer['NetCall'] = ['Number', 'NetMail', 'Greatsun Address',"Information",'Introduce'];



export default class HotTagsFooter extends React.Component {
  state = {
    selectedTags: [],
  };

  handleChange(tag, checked) {
    const { selectedTags } = this.state;
    const nextSelectedTags = checked
      ? [tag]
      : selectedTags.filter(t => t !== tag);

    this.setState({ selectedTags: nextSelectedTags });
    if (checked)
      this.props.setSubTags(tag)
    else this.props.setSubTags("All")
  }
  renderCarousel = () => {
    let pageList = []
    let count = 0
    pageList[0] = new Array()
    const { selectedTags } = this.state;
    if (this.props.currentTags) {
      for (let item in this.props.currentTags) {
        let tag = tagsFromServer[item]
        if ((item + 1) % 3 === 0) {
          count++;
          pageList[count] = new Array()
          pageList[count].push(<CheckableTag

            checked={selectedTags.indexOf(tag) > -1}
            onChange={checked => this.handleChange(tag, checked)}
          >
            {tag}gg
          </CheckableTag>)
        }
        else {
          pageList[count].push(<CheckableTag

            checked={selectedTags.indexOf(tag) > -1}
            onChange={checked => this.handleChange(tag, checked)}
          >
            {tag} gg
          </CheckableTag>)
        }
      }

      let st=pageList.map((item, index) =>
      <div className="Page">
        {
         item.map(tag => tag)
        }
      </div>)
    
      return (
      <Carousel >
        {st}
      </Carousel>)

    }
  }
  render() {
    const { selectedTags } = this.state;
    if (!this.props.currentTags) return null
    if (!tagsFromServer[this.props.currentTags]) return null
    return (
      <div className="HotTagsFooter">
        {this.props.currentTags ?
          tagsFromServer[this.props.currentTags].map(tag => (
            <CheckableTag
              key={tag}
              checked={selectedTags.indexOf(tag) > -1}
              onChange={checked => this.handleChange(tag, checked)}
            >
              {tag}
            </CheckableTag>
          ))
          :
          null}
        
      </div>
    );
  }
}


// $checkEditable=false