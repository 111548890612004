import React, { Component } from 'react';




import { withRouter, Route, Switch, Redirect, Link } from 'react-router-dom';
//Redux component
import { connect } from 'react-redux'
import {
  loginGG,
  checkLogged_and_Login_automatically,
  uploadAddressImage, searchAddress,
  createPage,
  getPageInfo,
  getCatUserPage,
  createCategory,
  getPostTranslation,
  setCurrentPostView,
  followPage,
  getCommentList,
  submitCmt
} from './Service/Redux/service'
import { bindActionCreators } from 'redux'
import { Editor } from 'slate-react'
import { Layout, Input, Modal, Upload, message, Icon, Select, Button, Divider } from 'antd';

import UserFooter from './UserFooter'
import UserHeader from './UserHeader'
import ResultList from './ResultList'
import HotTagsSider from './HotTagsSider'
import CommentListContainer from './Comment/CommentList'

import CannerEditor from 'canner-slate-editor'

import { Value } from 'slate'



import './OtherUserPageView.css'
import logo from './logo.png'
const {
  Header, Footer, Sider, Content,
} = Layout;


const menuToolbarOption = [

];

const Option = Select.Option;


const initialEditorValue = Value.fromJSON({
  document: {
    nodes: [
      {
        object: "block",
        type: "paragraph",
        nodes: [
          {
            object: "text",
            leaves: [
              {
                text: "Nội dung bài viết"
              }
            ]
          }
        ]
      }
    ]
  }
});

class OtherUserPageView extends Component {


  constructor(props) {
    super(props);
    this.state = {
      uploadModalVisible: false,
      createPageModalVisible: false,
      fileList: [],
      uploading: false,
      address: "",
      detail: "",
      currentTags: "NetWeb",
      currentSubTags: "All",
      currentSideTag: "All",
      desc: "",
      createPageName: null,
      createCatModalVisible: false,
      createCatName: null,
      postTranslateData: [],
      languageLeft: [],
      languageRight: []
    }
  }

  showModal = () => {
    this.setState({
      uploadModalVisible: true,
    });
  }

  handleOk = (e) => {
    console.log(e);
    this.setState({
      uploadModalVisible: false,
    });
  }

  handleCancel = (e) => {

    this.setState({
      uploadModalVisible: false,
    });
  }
  login = () => {
    this.props.loginGG();
  }


  componentDidMount() {
    this.props.checkLogged_and_Login_automatically();
    if (this.props.currentPost) {
      getPostTranslation(this.props.currentPost.id, (data) => this.setTranslateData(this.props.currentPost.id, data))

      this.props.getPageInfo(this.props.currentPost.UID);
      this.props.getCatUserPage(this.props.currentPost.UID);
      this.props.getCommentList(this.props.match.params.uid)
    }



  }
  beforeUpload = (file) => {
    this.setState({ fileList: [file] });
    return false;

  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):


    if (this.props.currentPost !== prevProps.currentPost) {
      getPostTranslation(this.props.currentPost.id, (data) => this.setTranslateData(this.props.currentPost.id, data))
    }

  }


  setTranslateData = (id, data) => {
    let languageLeft = [];
    let languageRight = [];
    for (let i = 0; i < data.length; i++) {
      languageLeft[i] = 'none';
      languageRight[i] = 'none';
    }
    this.setState({ postTranslateData: data, languageLeft, languageRight })
  }

  setTags = (tag) => {
    this.setState({ currentTags: tag, currentSubTags: "All" })
  }
  setSubTags = (tag) => {
    this.setState({ currentSubTags: tag });
  }
  handleUpload = () => {
    const { fileList } = this.state;
    this.props.uploadAddressImage(fileList[0], this.props.userInfo, this.state.address, this.state.detail, this.state.currentTags, this.state.currentSubTags, this.state.currentSideTag)
    this.setState({
      uploading: true,
      uploadModalVisible: false
    });
  }

  onAddressChange = (e) => {
    this.setState({ address: e.target.value })
  }
  onDetailChange = (e) => {
    this.setState({ detail: e.target.value })
  }
  onDescChange = (e) => {
    this.setState({ desc: e.target.value })
  }
  searchForAddress = (address) => {
    console.log(address)
    this.props.searchAddress(address);


  }

  openCreatePageModal = () => {
    this.setState({ createPageModalVisible: true })
  }
  handleCancelCreatePageModal = () => {
    this.setState({ createPageModalVisible: false })
  }
  createPage = () => {
    if (this.state.createPageName) {
      this.props.createPage(this.state.createPageName, this.props.userInfo)
    }
    this.setState({ createPageModalVisible: false })


  }


  createCategory = () => {
    if (this.props.userInfo) {

      this.props.createCategory(this.props.userPageCat, this.state.currentTags, this.state.createCatName, this.props.userInfo)
      this.setState({ createCatModalVisible: false });
    }
  }
  handleCancelCreateCatModal = () => {
    this.setState({ createCatModalVisible: false });
  }

  openCreateCatModal = () => {
    this.setState({ createCatModalVisible: true })
  }

  setSideTags = (tags) => {
    this.setState({ currentSideTag: tags })
  }

  setLangLeft = (index, language) => () => {
    let temp = this.state.languageLeft.map(item => item);
    temp[index] = language;
    this.setState({ languageLeft: temp });

  }


  setLangRight = (index, language) => () => {
    let temp = this.state.languageRight.map(item => item);
    temp[index] = language;
    this.setState({ languageRight: temp });

  }


  handleSubmitCmt=(content)=>{
    submitCmt(this.props.userInfo,this.props.match.params.uid,content)
  }



  render() {
    let defaultPost = this.props.currentPost.detailt;
    if (this.state.postTranslateData.filter(e => e.data.language === "default").length > 0) {
      defaultPost = this.state.postTranslateData.filter(e => e.data.language === "default")[0];
    }
    console.log(defaultPost);

    let postTranslateArray = [];
    if (this.state.languageLeft.length>1)
    for (let i = 0; i < this.state.languageLeft.length; i++) {
      console.log("create translate array");
      console.log(this.state.languageLeft[i]);
      console.log(this.state.languageRight[i]);
      let langLeft = this.state.languageLeft[i];
      let langRight = this.state.languageRight[i];
      console.log(this.state.postTranslateData.filter(e => e.data.language === langLeft));
      let dataLeft;
      let dataRight;
      for (let i = 0; i < this.state.postTranslateData.length; i++) {
        if (this.state.postTranslateData[i].language === langLeft)
          dataLeft = this.state.postTranslateData[i];
        if (this.state.postTranslateData[i].language === langRight)
          dataRight = this.state.postTranslateData[i];
      }
      console.log(dataLeft);
      console.log(dataRight);
      
        postTranslateArray.push(
          <div className="PostTranslateItem">
            <div className="Post">
              <Select placeholder="Ngôn ngữ" style={{ width: 120 }}>
                {this.state.postTranslateData ?
                  this.state.postTranslateData.map(item => <Option value={item.language} onClick={this.setLangLeft(i, item.language)}>{item.language}</Option>)
                  : null}

              </Select>
              {this.state.languageLeft[i] !== "none" ?

                <CannerEditor
                  value={Value.fromJSON(JSON.parse(dataLeft.data.detailt))}
                  menuToolbarOption={menuToolbarOption}
                />
                : null}

            </div>
            <div className="Post">
              <Select placeholder="Ngôn ngữ" style={{ width: 120 }}>
                {this.state.postTranslateData ?
                  this.state.postTranslateData.map(item => <Option value={item.language} onClick={this.setLangRight(i, item.language)}>{item.language}</Option>)
                  : null}

              </Select>
              {this.state.languageRight[i] !== "none" ?
                <CannerEditor
                  value={Value.fromJSON(JSON.parse(dataRight.data.detailt))}
                  menuToolbarOption={menuToolbarOption}
                />
                : null}
            </div>
          </div>

        )
      
    }

    return (
      <div className="UserPage">


        <Layout>

          <Header>
            <Link to="/"> <img style={{ width: 150, height: 50 }} src={logo}></img></Link>
            <UserHeader openCreateCatModal={this.openCreateCatModal} isUserPage={true} openCreatePageModal={this.openCreatePageModal} setTags={this.setTags} showModal={this.showModal} login={this.login} userInfo={this.props.userInfo} />
          </Header>

          {this.props.userPageInfo ?
            <div className="PageName_OtherUserPageView">
              <Button className="FollowButton" onClick={followPage(this.props.userInfo, this.props.userPageInfo.pageName, this.props.userPageInfo.UID)}>
                {this.props.followPageList.filter(e => e.name === this.props.userPageInfo.pageName).length > 0 ? "Đang theo dõi" : "Theo dõi"}
              </Button>
              <div> {this.props.userPageInfo.pageName}</div>

            </div>
            : null}
          <Content>


            <Input.Search
              placeholder="Tìm kiếm"
              onSearch={value => this.searchForAddress(value)}
              style={{ width: 200 }}
            />
            <UserFooter currentTags={this.state.currentTags} setSubTags={this.setSubTags} />
          </Content>

          <Footer>



          </Footer>
          <Layout>


            <Layout>
              <Sider>
                {/* {this.props.userPageCat ?
                  <HotTagsSider setSideTags={this.setSideTags} tagsList={this.props.userPageCat.catList[this.state.currentTags]} />
                  : null} */}
              </Sider>

              <Content className="ContentResList">

                <div >
                  {this.props.currentPost ?
                    <div className="PostContent">
                      <h1 className="PostTitle">{this.props.currentPost.address}</h1>
                      <Select placeholder="Ngôn ngữ" style={{ width: 120 }}>
                        {this.state.postTranslateData ?
                          this.state.postTranslateData.map(item => <Option value={item.language} onClick={() => this.props.setCurrentPostView(item.data)}>{item.language}</Option>)
                          : null}

                      </Select>
                      <CannerEditor
                        value={Value.fromJSON(JSON.parse(this.props.currentPost.detailt))}
                        menuToolbarOption={menuToolbarOption}
                        readOnly={true}
                      />
                      <CommentListContainer onSubmit={this.handleSubmitCmt} comments={this.props.cmtList}/>
                      <Divider />
                      <div className="PostTranslateParrarel" >
                        {postTranslateArray}
                      </div>
                    </div>
                    : null}

                </div>

              </Content>

            </Layout>
          </Layout>
        </Layout>
      </div>




    );
  }
}

function mapState2Props(state) {
  return {
    userInfo: state.accountReducer.userInfo,
    resList: state.accountReducer.resList,
    userPageInfo: state.accountReducer.userPageInfo,
    userPageCat: state.accountReducer.userPageCat,
    currentPost: state.accountReducer.currentPost,
    followPageList: state.accountReducer.followPageList,
    cmtList :state.accountReducer.cmtList
  };
}
const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    loginGG,
    checkLogged_and_Login_automatically,
    uploadAddressImage,
    searchAddress,
    createPage,
    getPageInfo,
    getCatUserPage,
    createCategory,
    setCurrentPostView,
    getCommentList

  }, dispatch)

}

export default withRouter(connect(mapState2Props, mapDispatchToProps)(OtherUserPageView));
