import React, { Component } from 'react';




import { withRouter, Route, Switch, Redirect, Link } from 'react-router-dom';
//Redux component
import { connect } from 'react-redux'
import { loginGG, 
          checkLogged_and_Login_automatically,
           uploadAddressImage, searchAddress, 
           createPage,
            getPageInfo, 
           getCatUserPage, 
           createCategory, 
           getPostTranslation, 
           setCurrentPostView,
           followPage } from './Service/Redux/service'
import { bindActionCreators } from 'redux'

import { Layout, Input, Modal, Upload, message, Icon, Select, Button } from 'antd';

import UserFooter from './UserFooter'
import UserHeader from './UserHeader'
import ResultList from './ResultList'
import HotTagsSider from './HotTagsSider'
import CannerEditor from 'canner-slate-editor'
import { Value } from 'slate'

import './OtherUserPageView.css'
import logo from './logo.png'
const {
  Header, Footer, Sider, Content,
} = Layout;


const menuToolbarOption = [

];

const Option = Select.Option;

class PageFollowedView extends Component {
    constructor(props) {
        super(props);
        this.state = {
          uploadModalVisible: false,
          createPageModalVisible: false,
          fileList: [],
          uploading: false,
          address: "",
          detail: "",
          currentTags: "Đưa tin",
          currentSubTags: "All",
          currentSideTag: "All",
          desc: "",
          createPageName: null,
          createCatModalVisible: false,
          createCatName: null,
          postTranslateData: []
        }
      }
    
      showModal = () => {
        this.setState({
          uploadModalVisible: true,
        });
      }
    
      handleOk = (e) => {
        console.log(e);
        this.setState({
          uploadModalVisible: false,
        });
      }
    
      handleCancel = (e) => {
    
        this.setState({
          uploadModalVisible: false,
        });
      }
      login = () => {
        this.props.loginGG();
      }
    
    
      componentDidMount() {
        this.props.checkLogged_and_Login_automatically();
       
     
          this.props.getPageInfo(this.props.match.params.uid);
          this.props.getCatUserPage(this.props.match.params.uid);
    
        
    
    
    
      }
      beforeUpload = (file) => {
        this.setState({ fileList: [file] });
        return false;
    
      }
    
      componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
    
    
        if (this.props.currentPost !== prevProps.currentPost) {
          getPostTranslation(this.props.currentPost.id, (data) => this.setTranslateData(this.props.currentPost.id, data))
        }
    
      }
    
    
      setTranslateData = (id, data) => {
    
        this.setState({ postTranslateData: data })
      }
    
      setTags = (tag) => {
        this.setState({ currentTags: tag, currentSubTags: "All" })
      }
      setSubTags = (tag) => {
        this.setState({ currentSubTags: tag });
      }
      handleUpload = () => {
        const { fileList } = this.state;
        this.props.uploadAddressImage(fileList[0], this.props.userInfo, this.state.address, this.state.detail, this.state.currentTags, this.state.currentSubTags, this.state.currentSideTag)
        this.setState({
          uploading: true,
          uploadModalVisible: false
        });
      }
    
      onAddressChange = (e) => {
        this.setState({ address: e.target.value })
      }
      onDetailChange = (e) => {
        this.setState({ detail: e.target.value })
      }
      onDescChange = (e) => {
        this.setState({ desc: e.target.value })
      }
      searchForAddress = (address) => {
        console.log(address)
        this.props.searchAddress(address);
    
    
      }
    
      openCreatePageModal = () => {
        this.setState({ createPageModalVisible: true })
      }
      handleCancelCreatePageModal = () => {
        this.setState({ createPageModalVisible: false })
      }
      createPage = () => {
        if (this.state.createPageName) {
          this.props.createPage(this.state.createPageName, this.props.userInfo)
        }
        this.setState({ createPageModalVisible: false })
    
    
      }
    
    
      createCategory = () => {
        if (this.props.userInfo) {
    
          this.props.createCategory(this.props.userPageCat, this.state.currentTags, this.state.createCatName, this.props.userInfo)
          this.setState({ createCatModalVisible: false });
        }
      }
      handleCancelCreateCatModal = () => {
        this.setState({ createCatModalVisible: false });
      }
    
      openCreateCatModal = () => {
        this.setState({ createCatModalVisible: true })
      }
    
      setSideTags = (tags) => {
        this.setState({ currentSideTag: tags })
      }
      render() {
    
        return (
          <div className="UserPage">
    
    
            <Layout>
    
              <Header>
                <Link to="/"> <img style={{ width: 150, height: 50 }} src={logo}></img></Link>
                <UserHeader openCreateCatModal={this.openCreateCatModal} isUserPage={true} openCreatePageModal={this.openCreatePageModal} setTags={this.setTags} showModal={this.showModal} login={this.login} userInfo={this.props.userInfo} />
              </Header>
    
              {this.props.userPageInfo ?
                <div className="PageName">
                                   

                                                {this.props.userPageInfo.pageName}
                </div>
                : null}
              <Content>
    
    
                <Input.Search
                  placeholder="Tìm kiếm"
                  onSearch={value => this.searchForAddress(value)}
                  style={{ width: 200 }}
                />
                <UserFooter currentTags={this.state.currentTags} setSubTags={this.setSubTags} />
              </Content>
    
              <Footer>
    
    
    
              </Footer>
              <Layout>
    
    
                <Layout>
                  <Sider>
                    {/* {this.props.userPageCat ?
                      <HotTagsSider setSideTags={this.setSideTags} tagsList={this.props.userPageCat.catList[this.state.currentTags]} />
                      : null} */}
                  </Sider>
    
                  <Content className="ContentResList">
    
                  

                    {this.props.userPageInfo?
                    <ResultList showOnlyPostOfThisUser={true} userToShow={this.props.userPageInfo.UID} currentSideTag={this.state.currentSideTag} currentTags={this.state.currentTags} currentSubTags={this.state.currentSubTags} resultList={this.props.resList} />
                        :null}
           
                  </Content>
    
                </Layout>
              </Layout>
            </Layout>
          </div>
    
    
    
    
        );
      }
}


function mapState2Props(state) {
    return {
      userInfo: state.accountReducer.userInfo,
      resList: state.accountReducer.resList,
      userPageInfo: state.accountReducer.userPageInfo,
      userPageCat: state.accountReducer.userPageCat,
      currentPost: state.accountReducer.currentPost,
      followPageList: state.accountReducer.followPageList
    };
  }
  const mapDispatchToProps = dispatch => {
    return bindActionCreators({
      loginGG,
      checkLogged_and_Login_automatically,
      uploadAddressImage,
      searchAddress,
      createPage,
      getPageInfo,
      getCatUserPage,
      createCategory,
      setCurrentPostView
  
    }, dispatch)
  
  }
  
  export default withRouter(connect(mapState2Props, mapDispatchToProps)(PageFollowedView));
  