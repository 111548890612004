import React, { Component } from 'react'
import { Avatar, Menu, Layout, Button, Tabs, Modal, Input } from 'antd'

import { withRouter, Route, Switch, Redirect, Link } from 'react-router-dom';
//Redux component
import { connect } from 'react-redux'
import {

  addFriend,
  getUserProfile,
  getFriendList
} from './Service/Redux/service'
import { bindActionCreators } from 'redux'
import { ChatFeed, Message } from 'react-chat-ui'
import Iframe from 'react-iframe'
import { submitChatText, getChatContent, clearChat } from './Service/Redux/service'
import './UserProfilePage.css'

const TabPane = Tabs.TabPane;


class UserProfilePage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      profileData: null,
      friendList: [],
      chatModalVisible: false,
      currentChatValue: '',
      currentReceiver: null,
      videoChatVisible: false,
      messages: [
        new Message({
          id: 1,
          message: "I'm the recipient! (The person you're talking to)",
        }), // Gray bubble
        new Message({ id: 0, message: "I'm you -- the blue bubble!" }), // Blue bubble
      ],
    }
  }


  componentDidMount() {
    getUserProfile(this.props.match.params.uid, this.setUserProfile)
    getFriendList(this.props.match.params.uid, this.setFriendList)

  }

  openVideoChat = () => {
    this.setState({ videoChatVisible: true })
  }
  onCloseVideoChat = () => {
    this.setState({ videoChatVisible: false })
  }
  setFriendList = (list) => {
    this.setState({ friendList: list })
  }

  setUserProfile = (profile) => {
    this.setState({ profileData: profile });

  }
  handleCancel = (e) => {

    this.setState({
      chatModalVisible: false,
    });
    this.props.clearChat();
  }
  onChatValueChange = (e) => {
    this.setState({ currentChatValue: e.target.value });
  }

  openChatModal = (currentReceiver) => () => {
    this.setState({ chatModalVisible: true, currentReceiver })
    this.props.getChatContent(this.props.userInfo, currentReceiver)

  }

  sendMessage = () => {
    submitChatText(this.props.userInfo, this.state.currentReceiver, this.state.currentChatValue)
  }

  //SUDO
  sendMessagetoStrangers = (uid) => {
    let info = this.props.userInfo;
    if (info) {
      let owner = this.props.currentViewID === this.props.userInfo.uid ? true : false;
      if (owner) {

      }
      else {
        this.props.submitChatText(this.props.userInfo.uid, this.state.chatContentList);
        this.props.listening_2_ChatListUpdate((item) => {
          if (this.props.prevList !== this.props.currentList) {
            this.updateList(this.props.currentList);
            this.gainControlFromCurrentViewUser(this.props.currentUID, (res) => {
              if (res) {
                this.user.getChatList(item => {
                  this.showChatItem(item);
                  this.OnlineList(item.user);

                })
              }
            })
          }
          let randomArr = [0, 1, 2, 3, 5];
          randomArr.sort(function (a, b) {
            if (a + '' + b > b + '' + a) return -1
            else return 1;
          })

        })
        this.checkForOwner((responder) => {
          if (responder.isOnline) {
            this.getInfoOfRepsonder(responder, (item) => {
              item.authUser();
              for (this.user in item.list) {
                if (this.user.uid !== this.props.userInfo.uid) {
                  return this.enableChat(this.user);
                  if (this.props.isEnabling) {
                    item.continueToChat((user) => {
                      user.getListOfFriend(uid);
                    })
                  }
                }
              }
            })
          }

        })
      }
    }
    if (this.requestGroupChat) {
      this.addFriendToGroup(this.userInfo.uid);
      this.gainUserForOnlineStatus(this.props.userInfo.chatList);
      let time_true = this.aboveFunc.getOnline();
      for (this.user of time_true) {
        this.user.setListeningToServer((this.chat_item), (res) => {
          res.showToChatl();
          res.showVideoRequest((videoScreen) => {
            this.windows.width = videoScreen.width;
            this.windows.heigh = videoScreen.heigh;
            this.getSyncReation(videoScreen)
            this.getUserInProfilePage(this.props.userInfo);
            this.highLightCallingUser(this.user);


          })
        })
      }
    }

  }


  render() {
    let userID = this.props.match.params.uid;
    let listFriend = (<div className="FriendList">
      {
        this.state.friendList.map(item =>
          <div className="FriendListItem">
            <Avatar src={item.photoURL} />
            <div className="UserName">  {item.username} </div>
            <div style={{ marginLeft: 'auto' }}>
              <Button shape="circle" size="small" icon="message" onClick={this.openChatModal(item)} />
              <Button style={{ marginLeft: 'auto' }} shape="circle" size="small" icon="phone" onClick={this.openVideoChat} />
            </div>
          </div>
        )
      }
    </div>)
    return (
      <div className="UserProfilePage">
        <Modal
          title={this.state.currentReceiver ? this.state.currentReceiver.username : "Tin"}
          visible={this.state.videoChatVisible}
          onOk={this.onCloseVideoChat}
          onCancel={this.onCloseVideoChat}

        >
          <Iframe url="https://voipserver-greatsun.herokuapp.com/"
            width="450px"
            height="450px"
            id="myId"
            className="myClassname"
            display="initial"
            position="relative"
            allow="camera *;"
            allowFullScreen />
        </Modal>
        <Modal
          title={this.state.currentReceiver ? this.state.currentReceiver.username : "Tin"}
          visible={this.state.chatModalVisible}
          onOk={this.handleCancel}
          onCancel={this.handleCancel}

        >

          <ChatFeed
            messages={this.props.chatContentList} // Boolean: list of message objects
            isTyping={this.state.is_typing} // Boolean: is the recipient typing
            hasInputField={false} // Boolean: use our input, or use your own
            showSenderName // show the name of the user who sent the message
            bubblesCentered={false} //Boolean should the bubbles be centered in the feed?
            // JSON: Custom bubble styles
            bubbleStyles={
              {
                text: {
                  fontSize: 15
                },
                chatbubble: {
                  borderRadius: 70,
                  padding: 10
                }
              }
            }
          />
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Input.TextArea row={2} value={this.state.currentChatValue} onChange={this.onChatValueChange} />
            <Button style={{ marginLeft: 'auto' }} shape="circle" icon="check" onClick={this.sendMessage} />

          </div>

        </Modal>
        {this.state.profileData ?
          <div className="BasicInfo">

            <Avatar size={120} src={this.state.profileData.photoURL} />
            <div className="UserName"> {this.state.profileData.displayName} </div>
          </div> :
          null}
        {this.props.userInfo ?
          this.props.match.params.uid !== this.props.userInfo.uid ?
            <Button onClick={() => addFriend(this.props.userInfo, userID)}> Kết bạn </Button>
            : null
          : null
        }

        {this.state.profileData ?
          <Tabs defaultActiveKey="1" >
            <TabPane tab="Thông tin" key="1">Thông tin</TabPane>
            <TabPane tab={"Bạn bè (" + this.state.friendList.length + ")"} key="2">{listFriend} </TabPane>
            <TabPane tab="Các trang" key="3">Các trang</TabPane>
          </Tabs>
          : null
        }


      </div>
    )
  }
}



function mapState2Props(state) {
  return {
    userInfo: state.accountReducer.userInfo,
    chatContentList: state.accountReducer.chatContentList
  };
}
const mapDispatchToProps = dispatch => {
  return bindActionCreators({

    getChatContent,
    clearChat

  }, dispatch)

}

export default withRouter(connect(mapState2Props, mapDispatchToProps)(UserProfilePage));
