import React, { Component } from 'react'
import HotTagsFooter from './HotTagsFooter'
export default class UserFooter extends Component {
  render() {
    return (
      <div>
          <HotTagsFooter  currentTags={this.props.currentTags} setSubTags={this.props.setSubTags}/>
      </div>
    )
  }
}
