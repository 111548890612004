import React, { Component } from 'react'


//Redux component
import { connect } from 'react-redux'
import { setCurrentPostView, uploadPostTranslation, getPostTranslation, likePost, unlikePost, sharePost } from './Service/Redux/service'
import { bindActionCreators } from 'redux'
import { Link } from "react-router-dom";

import RichTextEditor from './RichTextEditor'
import UploadImageCustom from './UploadImageCustom'
import CannerEditor from 'canner-slate-editor'
import { Value } from 'slate'
import { List, Card, Icon, Avatar, Tag, Modal, Input, Select, notification } from 'antd'
import Iframe from 'react-iframe'
import './ResultList.css'



import ReactTextCollapse from 'react-text-collapse';
import { lang } from 'moment';

const Option = Select.Option;

const TEXT_COLLAPSE_OPTIONS = {
    collapse: false,
    collapseText: '... thêm',
    expandText: 'thu lại',
    minHeight: 80,
    maxHeight: 900,
    textStyle: {
        color: 'blue',
        fontSize: '16px'
    }
}

const menuToolbarOption = [

];

const openSharedNotification = () => {
    notification.open({
        message: 'Đã chia sẻ',
        description: 'Bài đăng đã được chia sẻ cho bạn bè của bạn',
        onClick: () => {
            console.log('Notification Clicked!');
        },
    });
};

const initialEditorValue = Value.fromJSON({
    document: {
        nodes: [
            {
                object: "block",
                type: "paragraph",
                nodes: [
                    {
                        object: "text",
                        leaves: [
                            {
                                text: ""
                            }
                        ]
                    }
                ]
            }
        ]
    }
});

class ResultList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ImageHolderToogle: {},
            selectedTags: ['Anh'],
            TranslateHolderToogle: null,
            translateContent: initialEditorValue,
            postTranslateData: {}
        }
    }

    toogleImageHolder = (id) => () => {

        let newImageHolderToogle = this.state.ImageHolderToogle;
        if (newImageHolderToogle[id]) {
            newImageHolderToogle[id] = !newImageHolderToogle[id];
            this.setState({ ImageHolderToogle: newImageHolderToogle, TranslateHolderToogle: null })

        }
        else {
            newImageHolderToogle[id] = true;
            this.setState({ ImageHolderToogle: newImageHolderToogle, TranslateHolderToogle: null })
        }

    }

    toogleTranslateHolder = (id) => () => {
        if (this.state.TranslateHolderToogle !== id)
            this.setState({ TranslateHolderToogle: id, ImageHolderToogle: {} })
        else this.setState({ TranslateHolderToogle: null })
    }

    handleChange = (tag, checked) => {
        const { selectedTags } = this.state;
        const nextSelectedTags = checked
            ? [tag]
            : selectedTags.filter(t => t == tag);


        let temp = this.props.resultList.filter(item => item.id === this.state.TranslateHolderToogle);

        if (this.props.userInfo.uid === temp[0].UID) {
            this.showModal();
            this.setState({ currentTranslatePost: temp[0] })
        }

        this.setState({ selectedTags: nextSelectedTags });
    }


    showModal = () => {
        this.setState({
            uploadModalVisible: true,
        });
    }

    handleOk = (e) => {

        this.setState({
            uploadModalVisible: false,
        });
    }

    handleCancel = (e) => {

        this.setState({
            uploadModalVisible: false,
        });
    }


    handleUpload = () => {
        const { fileList } = this.state;
        console.log(this.refs.ImageUploader.state.fileList)
        let downloadList = [];
        downloadList = this.refs.ImageUploader.state.fileList.map(item => this.refs.ImageUploader.state.downloadLinkList[item.name]);
        console.log(downloadList);
        this.props.uploadPostTranslation(this.props.userInfo, this.state.TranslateHolderToogle,
            this.state.addriss, this.state.desc,
            JSON.stringify(this.state.translateContent),
            downloadList, this.state.currentTranslatePost.tag, this.state.currentTranslatePost.subTags,
            this.state.currentTranslatePost.sideTags,
            this.state.selectedTags[0])
        this.setState({ uploadModalVisible: false })

    }

    onAddressChange = (e) => {
        this.setState({ address: e.target.value })
    }
    onTranslateContentChange = ({ value }) => {
        this.setState({ translateContent: value })
    }
    onDescChange = (e) => {
        this.setState({ desc: e.target.value })
    }

    handleLanguageSelectChange = (value) => {
        console.log(`selected ${value}`);
    }

    setTranslateData = (id, data) => {

        let t_data = JSON.parse(JSON.stringify(this.state.postTranslateData));
        t_data[id] = data;
        this.setState({ postTranslateData: t_data })
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if ((this.props.resultList !== prevProps.resultList) || (this.props.currentSubTags !== prevProps.currentSubTags) || (this.props.currentTags !== prevProps.currentTags)) {
            let resultList = [];
            if (this.props.resultList)
                if (this.props.currentTags)
                    if (this.props.currentSideTag === "All") {
                        if (this.props.currentSubTags === "All")
                            if (this.props.currentTags === "All")

                                resultList = this.props.resultList
                            else resultList = this.props.resultList.filter(item => item.tag === this.props.currentTags)

                        else
                            resultList = this.props.resultList.filter(item => item.tag === this.props.currentTags && item.subTags === this.props.currentSubTags)

                    }
                    else {
                        if (this.props.currentSubTags === "All")
                            resultList = this.props.resultList.filter(item => item.tag === this.props.currentTags && item.sideTags === this.props.currentSideTag)
                        else
                            resultList = this.props.resultList.filter(item => item.tag === this.props.currentTags && item.subTags === this.props.currentSubTags && item.sideTags === this.props.currentSideTag)

                    }

            resultList.map(item => getPostTranslation(item.id, (data) => this.setTranslateData(item.id, data)))
            this.setState({ resultList: resultList })
        }
    }

    likeThisPost = (userInfo, id, oldLikeCount, isLiked) => () => {
        console.log(isLiked)
        if (isLiked)
            unlikePost(userInfo, id, oldLikeCount);
        else
            likePost(userInfo, id, oldLikeCount);
    }
    shareThisPost = (post) => () => {
        openSharedNotification();
        sharePost(this.props.userInfo, this.props.friendReqList, post)
    }
    render() {

        let resultList = []
        if (this.state.resultList) {
            resultList = this.state.resultList;
        }

        //get translate data

        //

        if (this.props.showOnlyPostOfThisUser) {
            resultList = resultList.filter(item => item.UID === this.props.userInfo.uid)
        }


        const tagsLanguage = ['Ả Rập', 'Albania', "Amharic", "Anh", "Amenia", "Azerbajian", "Ba Lan", "Ba Tư", "Bantu", "Basque", "Belarus", "Bengal", "Bosnia",
            "Bồ Đào Nha", "Bulgaria", "Catalan", "Cabuano", "Chichewa", "Corsi", "Creole ở Haiti", "Croatia", "Do Thái", "Đan Mạch", "Đức", "Estonia",
            "Flipino", "Frisia", "Gael Scotland", "Galicia", "George", "Gujara", "Hà Lan", "Hà Lan(Nam Phi)", "Hàn", "Hausa", "Hawaii", "Hindi", "Hmong",
            "Hungary", "Hy Lạp", "Iceland", "Igbo", "Indonesia", "Ireland", "Java", "Kannada", "Kazakh", "Khmer", "Kurd", "Kyrgyz", "Lào", "Latinh", "Latvia",
            "Litva", "Luxembourg", "Mã Lai", "Macedonia", "Malagasy", "Malayalam", "Malta", "Maori", "Marathi", "Mông Cổ", "Myanmar", "Na Uy", "Nepal", "Nga",
            "Nhật", "Pashto", "Pháp", "Phần Lan", "Punjab", "Runmani", "Samoa", "Séc", "Serbia", "Sesotho", "Shona", "Sindhi", "Sinhala", "Slovak", "Slovenia",
            "Somali", "Sunda", "Swahili", "Tajik", "Tamil", "Tây Ban Nha", "Tây Tạng", "Telugu", "Thái", "Thổ Nhĩ Kỳ", "Thuỵ Điển", "Trung (giản thể)", "Trung (phồn thể)", "Hán Việt", "Pinyin",
            "Ukraina", "Urdu", "Uzbek", "Việt", "Xứ Wales", "Ý", "Yiddish", "Yoruba", "Zulu"];
        const { selectedTags } = this.state;
        let languagleSelectPanel = (<div>
            {tagsLanguage.map(tag => (
                <Tag.CheckableTag
                    key={tag}
                    checked={selectedTags.indexOf(tag) > -1}
                    onChange={checked => this.handleChange(tag, checked)}
                >
                    {tag}
                </Tag.CheckableTag>))}
        </div>)

        let userLikeList = {};
        if (this.props.likedPostList) {
            this.props.likedPostList.forEach(item => {
                if (item.liked) {
                    userLikeList[item.id] = true;
                }
            });
        }
        console.log("userLikedList", userLikeList)
        return (

            <div className="ResultListContainer">
                <Modal
                    title="Upload"
                    visible={this.state.uploadModalVisible}
                    onOk={this.handleUpload}
                    onCancel={this.handleCancel}
                    width={900}
                >



                    <Input.TextArea placeholder="Tiêu đề" autosize value={this.state.address} onChange={this.onAddressChange} />
                    <Input.TextArea placeholder="Giới thiệu" autosize value={this.state.desc} onChange={this.onDescChange} />
                    <RichTextEditor value={this.state.translateContent} onDetailChange={this.onTranslateContentChange} />
                    <UploadImageCustom ref="ImageUploader" />

                </Modal>
                {this.props.resultList ?
                    <List


                        itemLayout="horizontal"

                        dataSource={resultList}
                        renderItem={(item) => {
                            let langCount=0
                            let comp=null
                            if (this.state.postTranslateData[item.id]) langCount=this.state.postTranslateData[item.id].length
                            return comp=
                            


                            <List.Item
                                key={item.address}
                                className="ListItem"

                            >
                                <div className="ItemContainer">

                                    <Card
                                        style={{ width: 1000 }}

                                        actions={[<a onClick={this.likeThisPost(this.props.userInfo, item.id, item.likeCount, userLikeList[item.id])}> {userLikeList[item.id] ? <Icon type="like" theme="filled" /> : <Icon type="like" />} {item.likeCount ? item.likeCount : 0}</a>,
                                        <a>Bình Luận</a>,
                                        <a onClick={this.toogleImageHolder(item.id)} > Media ({item.downloadList ? item.downloadList.length : '0'})</a>, <a onClick={this.toogleTranslateHolder(item.id)} >Dịch thuật</a>,
                                        <a onClick={this.shareThisPost(item)}> <Icon type="select" /> Chia sẻ</a>, <Icon type="ellipsis" />]}
                                    >
                                        <div className="CardTopFunc">
                                            <Select placeholder={"Ngôn ngữ "+"("+langCount+")"} style={{ width: 150 }} onChange={this.handleLanguageSelectChange}>
                                                {this.state.postTranslateData[item.id] ?
                                                    this.state.postTranslateData[item.id].map(item => <Option value={item.language}><Link onClick={() => this.props.setCurrentPostView(item.data)} to={'/view/' + item.data.id}>{item.language}</Link></Option>)
                                                    : null}

                                            </Select>
                                        </div>
                                        <Card.Meta

                                            title={<Link onClick={() => this.props.setCurrentPostView(item)} to={'/view/' + item.id}>{item.address}</Link>}
                                            description={<div  ><Link className="ResultList_username" to={'/account/' + item.UID} >{item.username.substring(0, 250)}</Link>    {item.created_date}</div>}

                                        />

                                        <div>{item.desc}</div>
                                    </Card>
                                    {this.state.ImageHolderToogle[item.id] ?
                                        <div className="ItemImageHolder">
                                            {item.downloadList ?
                                                item.downloadList.map(urlItem => urlItem.includes("mp4") ? <Iframe url={urlItem}
                                                    width="450px"
                                                    height="450px"
                                                    id="myId"
                                                    className="myClassname"
                                                    display="initial"
                                                    position="relative"
                                                    allow="camera *;"
                                                    allowFullScreen /> : <Avatar shape="square" size={180} icon="user" src={urlItem} />)
                                                : null
                                            }
                                        </div>
                                        : null}
                                    {this.state.TranslateHolderToogle === item.id ?
                                        languagleSelectPanel
                                        : null}
                                </div>

                            </List.Item>
                            }



                        }


                    />
                    : null}

            </div>
        )
    }
}


function mapState2Props(state) {
    return {
        userInfo: state.accountReducer.userInfo,
        likedPostList: state.accountReducer.likedPostList,
        friendReqList: state.accountReducer.friendReqList
    };
}
const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        setCurrentPostView,
        uploadPostTranslation
    }, dispatch)

}

export default connect(mapState2Props, mapDispatchToProps)(ResultList);
