import React, { Component } from 'react'
import { Upload, Icon, Modal } from 'antd';
import firebase_init from './Service/firebase'


export default class UploadImageCustom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            previewVisible: false,
            previewImage: '',
            fileList: [],
            downloadLinkList:{}
        };
         this.setDownloadLink=this.setDownloadLink.bind(this);
    }

    handleCancel = () => this.setState({ previewVisible: false })

    handlePreview = (file) => {
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true,
        });
    }

    handleChange = ({ fileList }) => this.setState({ fileList })
    beforeUpload = (file) => {
        this.setState(state => ({
            fileList: [...state.fileList, file],
        }));
        this.uploadFile(file);
        console.log(this.state.fileList)
        return false;
    }


    onRemove=(file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      }


    uploadFile=(file)=> {
        var uploadTask = firebase_init.storage().ref('doc_image').child(file.name).put(file);

        // Register three observers:
        // 1. 'state_changed' observer, called any time the state changes
        // 2. Error observer, called on failure
        // 3. Completion observer, called on successful completion
        let setDownloadLink=this.setDownloadLink;
        let filename=file.name;
        uploadTask.on('state_changed', function (snapshot) {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
           
        }, function (error) {
            // Handle unsuccessful uploads
        }, function() {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          
           
            uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                console.log(filename)
                console.log('File available at', downloadURL);
                setDownloadLink(filename,downloadURL)
            });
        });
    }
    setDownloadLink(filename,URL){
            let newDownloadList=this.state.downloadLinkList;
            newDownloadList[filename]=URL;
           // this.setState({downloadLinkList:newDownloadList})
    }
    render() {
        const { previewVisible, previewImage, fileList } = this.state;
        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div className="ant-upload-text">Upload</div>
            </div>
        );
        return (
            <div className="UploadImageCustom">
                <div className="clearfix">
                    <Upload
                        beforeUpload={this.beforeUpload}
                        listType="picture-card"
                        fileList={fileList}
                        onPreview={this.handlePreview}
                        onChange={this.handleChange}
                        onRemove={this.onRemove}
                    >
                        {fileList.length >= 30 ? null : uploadButton}
                    </Upload>
                    <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                        <img alt="example" style={{ width: '100%' }} src={previewImage} />
                    </Modal>
                </div>
            </div>
        );
    }
}
