import React, { Component } from 'react'
import { Tag } from 'antd';

const CheckableTag = Tag.CheckableTag;

const tagsFromServer = [ 'Home','NetWeb', "NetFilm", "MusicNet" ,"NetJob","NetLand" ,"NetCall"];
const tagsFromServer_main=[ 'Net','NetWeb', "NetFilm", "MusicNet" ,"NetJob","NetLand" ,"NetCall"];
export default class HotTags extends React.Component {
  state = {
    selectedTags: ['NetWeb'],
  };

  handleChange(tag, checked) {
    const { selectedTags } = this.state;
    const nextSelectedTags = checked
      ? [tag]
      : selectedTags.filter(t => t == tag);
    this.props.setTags(tag);
    this.setState({ selectedTags: nextSelectedTags });
  }

  render() {
    const { selectedTags } = this.state;
    return (
      <div className="HotTags">
       {this.props.isMainPage?
        tagsFromServer_main.map(tag => (
          <CheckableTag
            key={tag}
            checked={selectedTags.indexOf(tag) > -1}
            onChange={checked => this.handleChange(tag, checked)}
          >
            {tag}
          </CheckableTag>
        ))
        :
        tagsFromServer.map(tag => (
          <CheckableTag
            key={tag}
            checked={selectedTags.indexOf(tag) > -1}
            onChange={checked => this.handleChange(tag, checked)}
          >
            {tag}
          </CheckableTag>))
       }

      </div>
    );
  }
}
