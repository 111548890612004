import firebase_init, { auth, Authprovider, firestore, storageRef, database } from '../firebase.js';



import {

    setUserInfoAfterLogin,
    setResultList,
    signout,
    setUserPageInfo,
    setUserPageCat,
    setCurrentPost,
    setFollowPageList,
    setLikedPostList,
    setCmtList,
    setFriendReqList,
    setShareList,
    setChatContent,
    clearChatContent
} from './action';
import { getConfirmLocale } from 'antd/lib/modal/locale';




var moment = require('moment');
const AccountData_Table = "AccountData";

export function loginGG() {
    return (dispatch) => {
        auth.signInWithPopup(Authprovider)
            .then((result) => {
                const user = result.user;
                dispatch(setUserInfoAfterLogin(user));

                firestore.collection(AccountData_Table).doc(user.uid).get().then((doc) => {
                    if (doc.exists) {
                        console.log(doc)
                    }
                })
            });

    }
}

export function logOut() {
    return (dispatch) => {
        firebase_init.auth().signOut().then(() => {
            dispatch(signout());
        })
    }
}


export function checkLogged_and_Login_automatically() {
    return (dispatch) => {
        auth.onAuthStateChanged((user) => {
            if (user) {


               


                dispatch(setUserInfoAfterLogin(user))
               
                getFollowPage(dispatch,user.uid);
                getLikedPost(dispatch,user.uid);
                getFriendRequestList(dispatch,user.uid);
                writeUserData(user)
            }

        });
    }
}

function writeUserData(user){
            console.log("Write user info",user)
                database.ref("AccountDetail").child(user.uid).set({
                     displayName:user.providerData[0].displayName,
                     photoURL:user.providerData[0].photoURL
                })
}

export function uploadAddressImage(userInfo, address, desc, detailt, downloadList, tag, subTags, sideTags) {
    return (dispatch) => {

        var newPostKey = database.ref("Address").push().key;


        database.ref('Address').child(newPostKey).set({
            UID: userInfo.uid,
            username: userInfo.displayName,
            address,
            desc,
            detailt,
            likeCount:0,
            downloadList,
            created_date: moment().format("YYYY-MM-DD HH:mm:ss"),
            tag,
            subTags,
            sideTags
        });

        database.ref('PostTranslated/'+newPostKey+'/').child("default").set({
            UID: userInfo.uid,
            id:newPostKey,
            username: userInfo.displayName,
            address,
            desc,
            likeCount:0,
            detailt,
            downloadList,
            created_date: moment().format("YYYY-MM-DD HH:mm:ss"),
            tag,
            subTags,
        })
    }




}

export function uploadPostTranslation(userInfo, id, address, desc, detailt, downloadList, tag, subTags, sideTags, language) {
    return (dispatch) => {



        database.ref('PostTranslated/' + id + '/').child(language).set({
            UID: userInfo.uid,
            username: userInfo.displayName,
            id,
            address,
            desc,
            detailt,
            downloadList,
            created_date: moment().format("YYYY-MM-DD HH:mm:ss"),
            tag,
            subTags,
            sideTags
        });
    }




}



export function searchAddress(address) {
    return (dispatch) => {
        console.log(address)
        database.ref("Address").on("value", (snapshot) => {

            let data = [];
            snapshot.forEach(function (childSnapshot) {
                var childKey = childSnapshot.key;
                var childData = childSnapshot.val();
                if (childData.address.includes(address)) {
                    data.push({ id: childKey, ...childData })
                }
                // ...
            });
            dispatch(setResultList(data))
        })
    }
}


export function createPage(pageName, userInfo) {
    return (dispatch) => {


        database.ref('Pages').child(userInfo.uid).set({
            UID: userInfo.uid,
            username: userInfo.displayName,
            pageName

        });

    }

}

export function createCategory(oldCat, mainCatName, catName, userInfo) {
    return (dispatch) => {
        if (mainCatName==="Web") return;
        if (oldCat) {
            ///
            if (oldCat.catList[mainCatName])
                oldCat.catList[mainCatName].push(catName);
            else oldCat.catList[mainCatName] = [catName]




            ///
            database.ref('Categories').child(userInfo.uid).set({
                UID: userInfo.uid,
                username: userInfo.displayName,
                catList: oldCat.catList

            });
        }
        else {
            let newCat = {};
            newCat[mainCatName] = [catName];
            database.ref('Categories').child(userInfo.uid).set({
                UID: userInfo.uid,
                username: userInfo.displayName,
                catList: newCat

            });
        }


    }

}


export function getPageInfo(uid) {
    return (dispatch) => {
        console.log("getPage")
        database.ref("Pages").child(uid).once("value", (snapshot) => {
            dispatch(setUserPageInfo(snapshot.val()))
        })
    }
}

export function getCatUserPage(uid) {
    return (dispatch) => {
        console.log("getCat")
        database.ref("Categories").child(uid).on("value", (snapshot) => {
            dispatch(setUserPageCat(snapshot.val()))
        })
    }
}



export function setCurrentPostView(post) {
    console.log(post)
    return (dispatch) => {
        console.log("set post");
        dispatch(setCurrentPost(post))
    }
}


export function getPostTranslation(id,callback) {
    database.ref("PostTranslated/" + id + "/").once('value').then((snapshot) => {
        let translateData=[]
        if (snapshot.exists()) {
            
            snapshot.forEach(function (childSnapshot) {
                var childKey = childSnapshot.key;
                var childData = childSnapshot.val();
                translateData.push({language:childKey,data:childData});
                // 
            })
           
        }
        callback(translateData);
        
    })
}


export function followPage(userInfo,pageName,pageUID){

    database.ref('PageFollowed/' + userInfo.uid + '/').child(pageName).set({
                    pageName,
                    pageUID
    });
}

export function getFollowPage(dispatch,uid){
        
            console.log("uid",uid)
            database.ref("PageFollowed/" + uid + "/").once('value').then((snapshot) => {
                let pageList=[]
                if (snapshot.exists()) {
                    
                    snapshot.forEach(function (childSnapshot) {
                        var childKey = childSnapshot.key;
                        var childData = childSnapshot.val();
                        pageList.push({name:childKey,detail:childData});
                        // 
                    })
                   
                }
                dispatch(setFollowPageList(pageList))
        })
    
}

export function getLikedPost(dispatch,uid){
    database.ref("PostLiked/"+uid+"/").on('value',(snapshot)=>{
            let likedList=[];
            if (snapshot.exists()){
                    snapshot.forEach(function (childSnapshot){
                            var childKey=childSnapshot.key;
                            var childData=childSnapshot.val();
                            likedList.push({id:childKey,liked:childData.liked});
                    })
            }
            dispatch(setLikedPostList(likedList));
    })
}

export function likePost(userInfo,id,oldLikeCount){
    console.log("like")
    database.ref('PostLiked/'+userInfo.uid+'/').child(id).set({
                    liked:true
    })
    if (oldLikeCount)
    database.ref("Address").child(id).update({likeCount:oldLikeCount+1});
    else{
        database.ref("Address").child(id).update({likeCount:1});
    }
}

export function unlikePost(userInfo,id,oldLikeCount){
    console.log("unlike")
    database.ref('PostLiked/'+userInfo.uid+'/').child(id).update({
            liked:false
    })
    if (oldLikeCount)
        database.ref("Address").child(id).update({likeCount:oldLikeCount-1});
}


export function getCommentList(id){
    return (dispatch)=>{
        database.ref("Comments").child(id).on('value',(snapshot)=>{
            let cmtList=[];
            if (snapshot.exists()){
                snapshot.forEach(function (childSnapshot){
                    var childKey=childSnapshot.key;
                    var childData=childSnapshot.val();
                    cmtList.push({id:childKey,
                                    content:childData.content,
                                    created_date:childData.created_date,
                                    username:childData.username});
                })
            }
            dispatch(setCmtList(cmtList));
    
        })

    }
  
}

export function submitCmt(userInfo,id,content){
    var newPostKey = database.ref("Comments/"+id+'/').push().key;


    database.ref('Comments/'+id+'/').child(newPostKey).set({
        UID: userInfo.uid,
        username: userInfo.displayName,
        content,  
        created_date: moment().format("YYYY-MM-DD HH:mm:ss"),
      })
}

export function addFriend(userInfo,friendUID){
       
        database.ref('Account/'+friendUID+'/').child(userInfo.uid).set({
            status:"requesting",
            username:userInfo.displayName,
            photoURL:userInfo.photoURL
        })

}

export function acceptFriend(userInfo,friendUID,username,photoURL){
    database.ref('Account/'+userInfo.uid+'/').child(friendUID).set({
        status:"friend",
        username,
        photoURL
    })
    database.ref('Account/'+friendUID+'/').child(userInfo.uid).set({
        status:"friend",
        username:userInfo.displayName,
        photoURL:userInfo.photoURL
    })


}

export function denytFriend(userInfo,friendUID){
    database.ref('Account/'+userInfo.uid+'/').child(friendUID).set({
        status:"deny"
    })
    database.ref('Account/'+friendUID+'/').child(userInfo.uid).set({
        status:"deny"
    })


}

export function blockFriend(userInfo,friendUID){
    database.ref('Account/'+userInfo.uid+'/').child(friendUID).set({
        status:"blocked"
    })
   


}


export function unblockFriend(userInfo,friendUID){
    database.ref('Account/'+userInfo.uid+'/').child(friendUID).set({
        status:"deny"
    })
   

}


export function getFriendRequestList(dispatch,uid){
            
                database.ref('Account').child(uid).on('value',(snapshot)=>{
                    let friendReqList=[];
                    if (snapshot.exists()){
                        snapshot.forEach(function (childSnapshot){
                            var childKey=childSnapshot.key;
                            var childData=childSnapshot.val();
                            friendReqList.push({id:childKey,
                                            ...childData});
                        })
                    }
                    dispatch(setFriendReqList(friendReqList));
            
                })
            
}


export function sharePost(userInfo,friendList,post){
    let n=friendList.length;

    console.log("SHARE");
    console.log(friendList);
    console.log(post);
    let downloadList=[];
    if (post.downloadList)
            downloadList=post.downloadList;
    for (let i=0;i<n;i++)
    {

        database.ref("Netshare/"+friendList[i].id+'/').child(post.id).set({
            UID: post.UID,
            username: post.username,
            address:post.address,
            desc:post.desc,
            detailt:post.detailt,
            likeCount:post.likeCount,
            downloadList,
            created_date:post.created_date,
            tag:post.tag,
            subTags:post.subTags,
            sideTags:post.sideTags,
            shareUID:userInfo.uid,
            shareDisplayName:userInfo.displayName
        })
    }


  
}





export function getSharePostList(userInfo){
    return (dispatch)=>{
        console.log("GET SHARE")
        database.ref('Netshare').child(userInfo.uid).on('value',(snapshot)=>{
            let postList=[];
            if (snapshot.exists()){
                snapshot.forEach(function (childSnapshot){
                    var childKey=childSnapshot.key;
                    var childData=childSnapshot.val();
                    postList.push({id:childKey,
                                    ...childData});
                })
            }
            dispatch(setShareList(postList));
    
        })
    
    }
}


export function getUserProfile(uid,callback){
            database.ref("AccountDetail").child(uid).once('value',(snapshot)=>{
                if (snapshot.exists()){
                    let profile=snapshot.val();
                    callback(profile)
                }
            })
}

export function getFriendList(uid,callback){
    database.ref("Account").child(uid).once('value',(snapshot)=>{
        let friendList=[];
                    if (snapshot.exists()){
                        snapshot.forEach(function (childSnapshot){
                            var childKey=childSnapshot.key;
                            var childData=childSnapshot.val();
                            if (childData.status==="friend")
                            friendList.push({id:childKey,
                                            ...childData});
                        })
                    }
            callback(friendList);
            
    })
}

export function submitChatText(userInfo,receiverInfo,content){
    var newKey = database.ref("Chat").child(userInfo.uid).child(receiverInfo.id).push().key;
    database.ref("Chat").child(userInfo.uid).child(receiverInfo.id).child(newKey).set({
                uid:userInfo.uid,
                username:userInfo.displayName,
                message: content,
                id:0
    })
    database.ref("Chat").child(receiverInfo.id).child(userInfo.uid).child(newKey).set({
        uid:userInfo.uid,
        username:userInfo.displayName,
        message:content,
        id:1
})
}

export function getChatContent(userInfo,receiverInfo){
    return(dispatch)=>{
        database.ref("Chat").child(userInfo.uid).child(receiverInfo.id).on('value',(snapshot)=>{
            if (snapshot.exists){
                let list=[];
                snapshot.forEach((childSnapshot)=>{
                    var childData=childSnapshot.val();
                    list.push(childData)
                })
                dispatch(setChatContent(list))
            }
            console.log(snapshot.val());
        })
    }
  
}

export function clearChat(){
    return(dispatch)=>{
            dispatch(clearChatContent())
    }
}

export function addCatNetFeed(userInfo,catName,isTop){
       
    database.ref('NetFeedCat/'+userInfo.uid+'/').child(catName).set({
        isTop
    })

}
export function getCatNetFeed(userInfo,callback){
    
        database.ref("NetFeedCat").child(userInfo.uid).on('value',(snapshot)=>{
            if (snapshot.exists){
                let list=[];
                for (let item in snapshot.val()){
                    console.log(item)
                    list.push(item)
                }
                callback(list)
            }
          
        })
     
}