import React, { Component } from 'react';



import {
    Comment, Avatar, Form, Button, List, Input,Tooltip
} from 'antd';
import moment from 'moment';
import './CommentList.css'

const TextArea = Input.TextArea;

const CommentList = ({ comments }) => (
    <List
        dataSource={comments}
        header={`${comments.length} ${comments.length > 1 ? 'replies' : 'reply'}`}
        itemLayout="horizontal"
        renderItem={props => <Comment
            author={<a>{props.username}</a>}
            avatar={(
                <Avatar
                    src=""
                    alt="Han Solo"
                />
            )}
            content={(
                <p>{props.content}</p>
            )}
            datetime={(
                <Tooltip title={props.created_date}>
                    <span>{props.created_date}</span>
                </Tooltip>
            )} />}
    />
);

const Editor = ({
    onChange, onSubmit, submitting, value,
}) => (
        <div>
            <Form.Item>
                <TextArea rows={4} onChange={onChange} value={value} />
            </Form.Item>
            <Form.Item>
                <Button
                    htmlType="submit"
                    loading={submitting}
                    onClick={onSubmit}
                    type="primary"
                >
                    Bình luận
        </Button>
            </Form.Item>
        </div>
    );

export default class CommentListContainer extends Component {
    state = {
        comments: [],
        submitting: false,
        value: '',
    }

    handleSubmit = () => {
        if (!this.state.value) {
            return;
        }

        this.setState({
            value: '',
        });
        this.props.onSubmit(this.state.value)

    }

    handleChange = (e) => {
        this.setState({
            value: e.target.value,
        });
    }

    render() {
        const { comments } = this.props;
        const { submitting, value } = this.state;
        return (
            <div className="CommentlistContainer" >
                {comments ?
                    comments.length > 0 && <CommentList comments={comments} />
                    : null}
                <Comment
                    avatar={(
                        <Avatar
                            src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                            alt="Han Solo"
                        />
                    )}
                    content={(
                        <Editor
                            onChange={this.handleChange}
                            onSubmit={this.handleSubmit}
                            submitting={submitting}
                            value={value}
                        />
                    )}
                />
            </div>
        );
    }
}
