import React, { Component } from 'react'
//Redux component
import { connect } from 'react-redux'
import { setCurrentPostView, uploadPostTranslation, getPostTranslation, likePost, unlikePost, sharePost,addCatNetFeed,getCatNetFeed } from './Service/Redux/service'
import { bindActionCreators } from 'redux'
import { Link } from "react-router-dom";

import {
    Avatar, Tag, Input, Tooltip, Icon,Tabs,Button
} from 'antd'

import './NetFeed.css'
import Item from 'antd/lib/list/Item';
import { database } from 'firebase';


class EditableTagGroup extends React.Component {
    state = {
        tags: ['Tất cả', 'Tin tức'],
        inputVisible: false,
        inputValue: '',
    };

    handleClose = (removedTag) => {
        const tags = this.state.tags.filter(tag => tag !== removedTag);
        console.log(tags);
        this.setState({ tags });
    }

    showInput = () => {
        this.setState({ inputVisible: true }, () => this.input.focus());
    }

    handleInputChange = (e) => {
        this.setState({ inputValue: e.target.value });
    }

    handleInputConfirm = () => {
        const state = this.state;
        const inputValue = state.inputValue;
        let tags = state.tags;
        if (inputValue && tags.indexOf(inputValue) === -1) {
            tags = [...tags, inputValue];
        }
        console.log(tags);
        this.setState({
            tags,
            inputVisible: false,
            inputValue: '',
        });
    }

    saveInputRef = input => this.input = input

    render() {
        const { tags, inputVisible, inputValue } = this.state;
        return (
            <div>
                {tags.map((tag, index) => {
                    const isLongTag = tag.length > 20;
                    const tagElem = (
                        <Tag key={tag} closable={index !== 0} afterClose={() => this.handleClose(tag)}>
                            {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                        </Tag>
                    );
                    return isLongTag ? <Tooltip title={tag} key={tag}>{tagElem}</Tooltip> : tagElem;
                })}
                {inputVisible && (
                    <Input
                        ref={this.saveInputRef}
                        type="text"
                        size="small"
                        style={{ width: 78 }}
                        value={inputValue}
                        onChange={this.handleInputChange}
                        onBlur={this.handleInputConfirm}
                        onPressEnter={this.handleInputConfirm}
                    />
                )}
                {!inputVisible && (
                    <Tag
                        onClick={this.showInput}
                        style={{ background: '#fff', borderStyle: 'dashed' }}
                    >
                        <Icon type="plus" /> Thêm mục
            </Tag>
                )}
            </div>
        );
    }
}

class NetFeed extends Component {


    constructor(props){
        super(props);
        this.state={listTabTop:["Tất cả","Tin tức"],tabName:''}
    }

    
    componentDidMount(){
        getCatNetFeed(this.props.userInfo,(resList)=>{this.setState({listTabTop:resList})})
    }

    addTopTab=()=>{
        if (this.state.tabName!=='')
        {
            addCatNetFeed(this.props.userInfo,this.state.tabName,true)
        }
        

    }


    changeTabTop=(e)=>{
            console.log(e)
    }
    changeTabName=(e)=>{
        this.setState({tabName:e.target.value})
    }
    render() {
        let topTabs=this.state.listTabTop.map(item=> <Tabs.TabPane tab={item} key={item}></Tabs.TabPane>)
        return (
            <div>
                <div className="NetFeedHeader">
                    <Tabs defaultActiveKey={this.state.listTabTop[0]} onChange={this.changeTabTop}>
                       {topTabs}
                    </Tabs>,
                    <div className="BasicInfo">

                    </div>
                    <div style={{ marginTop: 10 }}>
                    <Tabs defaultActiveKey="1" onChange={this.changeTabTop}>
                        <Tabs.TabPane tab="Tin tức" key="1"></Tabs.TabPane>
                        <Tabs.TabPane tab="Thể thao" key="2"></Tabs.TabPane>
                        <Tabs.TabPane tab="Cửa hàng" key="3"></Tabs.TabPane>
                    </Tabs>
                    </div>

                    <Button onClick={this.addTopTab}>Thêm mục</Button>
                    <Input value={this.state.tabName} onChange={this.changeTabName} />
                  
                </div>
            </div>
        )
    }
}


function mapState2Props(state) {
    return {
        userInfo: state.accountReducer.userInfo,

    };
}
const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        setCurrentPostView,
        uploadPostTranslation
    }, dispatch)

}

export default connect(mapState2Props, mapDispatchToProps)(NetFeed);
