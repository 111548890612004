import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import UserPage from './UserPage'
import OtherUserPageView from './OtherUserPageView'
import PageFollowedView from './PageFollowedView'
import RichTextEditor from './RichTextEditor'
import UserProfilePage from './UserProfilePage'
import NetFeed from './NetFeed'
import * as serviceWorker from './serviceWorker';
import 'antd/dist/antd.css';


///redux
import thunkMiddleware from 'redux-thunk'
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import createHistory from 'history/createBrowserHistory';

import { ConnectedRouter, routerReducer, routerMiddleware, push } from 'react-router-redux'
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import { rootReducer } from './Service/Redux/reducer';



const history = createHistory()
const middleware = routerMiddleware(history)
const store = createStore(
    rootReducer,
    compose(
        applyMiddleware(middleware, thunkMiddleware),
        window.devToolsExtension ? window.devToolsExtension() : f => f,
    )
)




ReactDOM.render(<Provider store={store}>
    <ConnectedRouter history={history}>
        <div>
            <Router>
                <div>
                    <Route exact path="/" component={App} />
                    <Route path="/page" component={UserPage} />
                    <Route path="/view/:uid" component={OtherUserPageView} />
                    <Route path="/editor" component={RichTextEditor} />
                    <Route path="/followpage/:uid" component={PageFollowedView} />
                    <Route path='/account/:uid' component={UserProfilePage}/>
                    <Route path='/netfeed/' component={NetFeed}/>
                </div>
            </Router>
        </div>
    </ConnectedRouter>
</Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
