import React, { Component } from 'react'
import HotTags from './HotTags'
import { Avatar, Dropdown, Menu, Button, Icon } from 'antd'
import { BrowserRouter as Router, Route, Link } from "react-router-dom";


//Redux component
import { connect } from 'react-redux'
import { loginGG, logOut, acceptFriend } from './Service/Redux/service'
import { bindActionCreators } from 'redux'

class UserHeader extends Component {


    submitFriendAccept = (id, username, photoURL) => () => {
        acceptFriend(this.props.userInfo, id, username, photoURL);
    }

    render() {
        const menu = (
            <Menu>
                {this.props.userInfo ?
                    <Menu.Item><Link  to={'/account/'+this.props.userInfo.uid} >Hi, {this.props.userInfo.displayName}</Link> </Menu.Item>
                    : null}
                {this.props.isUserPage ?
                    <Menu.Item onClick={this.props.showModal} >Tải lên</Menu.Item>
                    : null}
                     <Menu.Item><Link to="/netfeed">Net Feed</Link></Menu.Item>
                    <Menu.Item><Link to="/page">Trang nhà</Link></Menu.Item>
                 
                {!this.props.isUserPage ?
                    <Menu.Item onClick={this.props.openCreatePageModal}>Tạo trang</Menu.Item>
                    : null}

                {this.props.isUserPage ?
                    <Menu.Item onClick={this.props.openCreateCatModal} >Tạo mục</Menu.Item>
                    : null}
                <Menu.Item onClick={this.props.logOut} >Đăng xuất</Menu.Item>


            </Menu>
        );


        let friendReqList = [];
        friendReqList = this.props.friendReqList ? this.props.friendReqList : [];
  
        const menuFriendNoti = (
            <Menu>
                {friendReqList.map(item=> 
                               

                                    item.status==="requesting"?
                                     <Menu.Item >  
                                            <Avatar src={item.photoURL} /> <a>{item.username}</a>  
                                           <Button onClick={this.submitFriendAccept(item.id, item.username, item.photoURL)} >Chấp nhập</Button>
                                     </Menu.Item>
                                     :null
                             

                            )
                }


            </Menu>
        );

        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'flex-end',
                justifyContent: 'flex-end'
            }}>
                <HotTags isMainPage={this.props.isMainPage?this.props.isMainPage:false} setTags={this.props.setTags} />
                <div>
                    {
                        this.props.userInfo ?
                            <Dropdown overlay={menu}>
                                <Avatar src={this.props.userInfo.photoURL}/>
                            </Dropdown>
                            : <Button onClick={this.props.login} >Đăng nhập</Button>
                    }
                    {
                        <Dropdown overlay={menuFriendNoti}>
                            <Icon type="bell" style={{ fontSize: '20px', marginLeft: '20px' }} />
                        </Dropdown>
                    }
                </div>
            </div>
        )
    }
}

function mapState2Props(state) {
    return {
        userInfo: state.accountReducer.userInfo,
        resList: state.accountReducer.resList,
        userPageInfo: state.accountReducer.userPageInfo,
        userPageCat: state.accountReducer.userPageCat,
        friendReqList: state.accountReducer.friendReqList

    };
}
const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        loginGG,


        logOut

    }, dispatch)

}

export default connect(mapState2Props, mapDispatchToProps)(UserHeader);
