import { createStore,combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux'



//ACtions Import
import {
   
    SET_USER_INFO_AFTER_LOGIN,
   SET_RESULT_LIST,
    SET_USER_PAGE_INFO,
    SIGNOUT,
    SET_USER_PAGE_CAT,
    SET_CURRENT_POST,
    SET_FOLLOW_PAGE_LIST,
    SET_LIKED_POST_LIST,
    SET_CMT_LIST,
    SET_FRIEND_REQ_LIST,
    SET_SHARE_LIST,
    SET_CHAT_CONTENT,
    CLEAR_CHAT_CONTENT
    } from './action' ;





export const DefaultAccountState = {
    isCheckingLoginInfo:false,
    isCheckingSignUpInfo:false, 
    isCompleteSignUpSuccessfully:false,

    isGettingUserInfo:false,
    num: 1,
    userInfo:null,
    resList:null,
    userPageInfo:null,
    userPageCat:null,
    other_userPageInfo:null,
    other_userPageCat:null,
    currentPost:null,
    followPageList:[],
    likedPostList:[],
    cmtList:[],
    friendReqList:[],
    shareList:[],
    chatContentList:[]
   
   
}



export const accountReducer = (state = DefaultAccountState , action) => {
    let count_temp=0;
    switch (action.type) {
        case SET_USER_INFO_AFTER_LOGIN: return {...state, userInfo:action.userInfo}
        case SIGNOUT:return{...state,userInfo:null};
        case SET_RESULT_LIST: return {...state,resList:action.data};
        case SET_USER_PAGE_INFO: return{...state,userPageInfo:action.data}
        case SET_USER_PAGE_CAT:return {...state,userPageCat:action.data}
        case SET_CURRENT_POST: return {...state,currentPost:action.data}
        case SET_FOLLOW_PAGE_LIST: return {...state,followPageList:action.list}
        case SET_LIKED_POST_LIST:return {...state,likedPostList:action.list}
        case SET_CMT_LIST:return {...state,cmtList:action.list}
        case SET_FRIEND_REQ_LIST: return {...state,friendReqList:action.list}
        case SET_SHARE_LIST: return {...state,shareList:action.list}
        case SET_CHAT_CONTENT:return {...state,chatContentList:action.list}
        case CLEAR_CHAT_CONTENT:return{...state,chatContentList:[]}
        default: return state;
    }
}


export const rootReducer = combineReducers({
    accountReducer,
   
    router: routerReducer
})


export function reloadData(data){
    this.setReloadData(data,(item)=>{
            item.map(sub=>{
                            for (let i=0;i<sub.length;i++){
                                        if (i.notExist()){
                                                alert(i);
                                                    this.SafeArray.getRoom();
                                        }
                            }
            })
    })
}