import React, { Component } from 'react'
import { Tag } from 'antd';

const CheckableTag = Tag.CheckableTag;


export default class HotTagsSider extends React.Component {
    state = {
        selectedTags: [],
    };

    handleChange(tag, checked) {
        const { selectedTags } = this.state;
        const nextSelectedTags = checked
            ? [tag]
            : selectedTags.filter(t => t !== tag);
      //  this.props.setTags(tag);

        this.setState({ selectedTags: nextSelectedTags });
        if (checked)
        this.props.setSideTags(tag)
        else this.props.setSideTags("All")
    }

    render() {
        const { selectedTags } = this.state;
        
        return (
            <div className="HotTagsSider">
                {this.props.tagsList ?
                    this.props.tagsList.map(tag => (
                        <CheckableTag
                            key={tag}
                            checked={selectedTags.indexOf(tag) > -1}
                            onChange={checked => this.handleChange(tag, checked)}
                        >
                            {tag}
                        </CheckableTag>
         
                ))
                :
                null }
      </div>
        );
    }
}
