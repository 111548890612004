import React, { Component } from 'react';


import './App.css';

import { withRouter, Route, Switch, Redirect ,Link} from 'react-router-dom';
//Redux component
import { connect } from 'react-redux'
import { loginGG, checkLogged_and_Login_automatically, uploadAddressImage,searchAddress,createPage } from './Service/Redux/service'
import { bindActionCreators } from 'redux'

import { Layout, Input, Modal, Upload, message, Icon } from 'antd';

import UserFooter from './UserFooter'
import UserHeader from './UserHeader'
import ResultList from './ResultList'
import  HotTagsFooterBottom from './HotTagsFooterBottom'
import logo from './logo.png'
import UserPage from './UserPage';
const {
  Header, Footer, Sider, Content,
} = Layout;



function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJPG = file.type === 'image/jpeg';
  if (!isJPG) {
    message.error('You can only upload JPG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJPG && isLt2M;
}


class App extends Component {


  constructor(props) {
    super(props);
    this.state = {
      uploadModalVisible: false,
      createPageModalVisible:false,
      fileList: [],
      uploading: false,
      address: "",
      detail: "",
      currentTags:"NetWeb",
      currentSubTags:"All",
      currentSideTag:"All",
      desc:"",
      createPageName:null,
      isViewingPage:false,
      viewPageUID:null
    }
  }

  showModal = () => {
    this.setState({
      uploadModalVisible: true,
    });
  }

  handleOk = (e) => {
   
    this.setState({
      uploadModalVisible: false,
    });
  }

  handleCancel = (e) => {
   
    this.setState({
      uploadModalVisible: false,
    });
  }
  login = () => {
    this.props.loginGG();
  }


  componentDidMount() {
    this.props.checkLogged_and_Login_automatically()
  }
  beforeUpload = (file) => {
    this.setState({ fileList: [file] });
    return false;

  }

  setTags=(tag)=>{
      this.setState({currentTags:tag,currentSubTags:"All"})
  }
  setSubTags=(tag)=>{
    this.setState({currentSubTags:tag});
  }
  handleUpload = () => {
    const { fileList } = this.state;
    this.props.uploadAddressImage(fileList[0],this.props.userInfo,this.state.address,this.state.detail,this.state.currentTags,this.state.currentSubTags)
    this.setState({
      uploading: true,
      uploadModalVisible:false
    });
  }

  onAddressChange=(e)=>{
    this.setState({address:e.target.value})
  }
  onDetailChange=(e)=>{
    this.setState({detail:e.target.value})
  }
  onDescChange=(e)=>{
    this.setState({desc:e.target.value})
  }
  searchForAddress=(address)=>{
    console.log(address)
    this.props.searchAddress(address);
 
 
  }

  openCreatePageModal=()=>{
    this.setState({createPageModalVisible:true})
  }
  handleCancelCreatePageModal=()=>{
    this.setState({createPageModalVisible:false})
  }
  createPage=()=>{
    if (this.state.createPageName){
              this.props.createPage(this.state.createPageName,this.props.userInfo)
    }
    this.setState({createPageModalVisible:false})
   
  
  }


  openUserPage=(UID)=>()=>{
    console.log("set page")
    this.setState({isViewingPage:true,
                    viewPageUID:UID})
  }

  render() {
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    const imageUrl = this.state.imageUrl;
    return (
    
      <div className="App">
         {!this.state.isViewingPage?
         <div>
        <Modal
          title="Upload"
          visible={this.state.uploadModalVisible}
          onOk={this.handleUpload}
          onCancel={this.handleCancel}
        >

          Đăng hình ảnh
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            fileList={this.state.fileList}

            beforeUpload={this.beforeUpload}
            onChange={this.handleChange}
          >
            {imageUrl ? <img src={imageUrl} alt="avatar" /> : uploadButton}
          </Upload>
          <Input.TextArea placeholder="Tiêu đề tấm hình" autosize value={this.state.address} onChange={this.onAddressChange} />
          <Input.TextArea placeholder="Giới thiệu" autosize value={this.state.desc} onChange={this.onDescChange} />
          <Input.TextArea placeholder="Thông tin bài đăng" autosize value={this.state.detail} onChange={this.onDetailChange} />
        </Modal>

        <Modal
          title="Tạo trang"
          visible={this.state.createPageModalVisible}
          onOk={this.createPage}
          onCancel={this.handleCancelCreatePageModal}
        >
              <Input placeholder="Tên trang bạn muốn tạo" value={this.state.createPageName} onChange={(e)=>this.setState({createPageName:e.target.value})} ></Input>

        </Modal>

        <Layout>
          <Header>
            <UserHeader isMainPage={true} openCreatePageModal={this.openCreatePageModal}  setTags={this.setTags}  showModal={this.showModal} login={this.login} userInfo={this.props.userInfo} />
          </Header>
          <Content>
          <Link to="/"> <img className="Logo" src={logo}></img></Link>
            <Input.Search
              placeholder="Tìm kiếm"
              onSearch={value => this.searchForAddress(value)}
              style={{ width: 200 }}
            />
          </Content>
          <Footer>
          
              <UserFooter currentTags={this.state.currentTags} setSubTags={this.setSubTags}/>
           
          </Footer>
          <ResultList currentSideTag={this.state.currentSideTag}  openUserPage={this.openUserPage} currentTags={this.state.currentTags} currentSubTags={this.state.currentSubTags} resultList={this.props.resList}/>
           
        </Layout>
        </div>
        :<div><UserPage uid={this.state.viewPageUID} /></div>}
        </div>
      
      
    );
  }
}

function mapState2Props(state) {
  return {
    userInfo: state.accountReducer.userInfo,
    resList:state.accountReducer.resList

  };
}
const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    loginGG,
    checkLogged_and_Login_automatically,
    uploadAddressImage,
    searchAddress,
    createPage

  }, dispatch)

}

export default withRouter(connect(mapState2Props, mapDispatchToProps)(App));
