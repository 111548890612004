import React, { Component } from 'react'
import { Tag } from 'antd';

const CheckableTag = Tag.CheckableTag;

const tagsFromServer = ['Phim', 'Hài', 'Du lịch', 'Văn thơ',"Địa chỉ", "Bản đồ","Công ty", "tìm việc", "Dịch vụ", "Ngành nghề","Cài đặt"];

export default class HotTagsFooterBottom extends React.Component {
  state = {
    selectedTags: [],
  };

  handleChange(tag, checked) {
    const { selectedTags } = this.state;
    const nextSelectedTags = checked
      ? [tag]
      : selectedTags.filter(t => t !== tag);
   
    this.setState({ selectedTags: nextSelectedTags });
  }

  render() {
    const { selectedTags } = this.state;
    return (
      <div className="HotTagsFooterBottomContainer">
       
        {tagsFromServer.map(tag => (
          <CheckableTag
            key={tag}
            checked={selectedTags.indexOf(tag) > -1}
            onChange={checked => this.handleChange(tag, checked)}
          >
            {tag}
          </CheckableTag>
        ))}
      </div>
    );
  }
}
