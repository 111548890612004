import React, { Component } from 'react';




import { withRouter, Route, Switch, Redirect, Link } from 'react-router-dom';
//Redux component
import { connect } from 'react-redux'
import { loginGG, checkLogged_and_Login_automatically, uploadAddressImage, searchAddress, createPage, getPageInfo, getCatUserPage, createCategory, getSharePostList } from './Service/Redux/service'
import { bindActionCreators } from 'redux'

import { Layout, Input, Modal, Upload, message, Icon, Menu } from 'antd';
import { Value } from 'slate'
import UserFooter from './UserFooter'
import UserHeader from './UserHeader'
import ResultList from './ResultList'
import HotTagsSider from './HotTagsSider'
import RichTextEditor from './RichTextEditor'
import UploadImageCustom from './UploadImageCustom'
import './UserPage.css'
import logo from './logo.png'
const {
  Header, Footer, Sider, Content,
} = Layout;

const { SubMenu } = Menu;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJPG = file.type === 'image/jpeg';
  if (!isJPG) {
    message.error('You can only upload JPG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJPG && isLt2M;
}


const initialEditorValue = Value.fromJSON({
  document: {
    nodes: [
      {
        object: "block",
        type: "paragraph",
        nodes: [
          {
            object: "text",
            leaves: [
              {
                text: "Nội dung bài viết"
              }
            ]
          }
        ]
      }
    ]
  }
});
class UserPage extends Component {


  constructor(props) {
    super(props);
    this.state = {
      uploadModalVisible: false,
      createPageModalVisible: false,
      fileList: [],
      uploading: false,
      address: "",
      detail: initialEditorValue,
      currentTags: "NetWeb",
      currentSubTags: "All",
      currentSideTag: "All",
      desc: "",
      createPageName: null,
      createCatModalVisible: false,
      createCatName: null,
      isViewShare: false
    }
  }

  showModal = () => {
    this.setState({
      uploadModalVisible: true,
    });
  }

  handleOk = (e) => {
    console.log(e);
    this.setState({
      uploadModalVisible: false,
    });
  }

  handleCancel = (e) => {

    this.setState({
      uploadModalVisible: false,
    });
  }
  login = () => {
    this.props.loginGG();
  }


  componentDidMount() {
    this.props.checkLogged_and_Login_automatically();

    if (this.props.userInfo) {
      this.props.getPageInfo(this.props.userInfo.uid);
      this.props.getCatUserPage(this.props.userInfo.uid);
    }

  }
  beforeUpload = (file) => {
    this.setState({ fileList: [file] });
    return false;

  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.userInfo !== prevProps.userInfo) {
      if (this.props.userInfo)
        this.props.getPageInfo(this.props.userInfo.uid);
      this.props.getCatUserPage(this.props.userInfo.uid);
    }
  }

  setTags = (tag) => {
    this.setState({ currentTags: tag, currentSubTags: "All", isViewShare: false })
  }
  setSubTags = (tag) => {
    this.setState({ currentSubTags: tag });
  }
  handleUpload = () => {
    const { fileList } = this.state;
    console.log(this.refs.ImageUploader.state.fileList)
    let downloadList = [];
    downloadList = this.refs.ImageUploader.state.fileList.map(item => this.refs.ImageUploader.state.downloadLinkList[item.name]);
    console.log(downloadList);
    this.props.uploadAddressImage(this.props.userInfo, this.state.address, this.state.desc, JSON.stringify(this.state.detail), downloadList, this.state.currentTags, this.state.currentSubTags, this.state.currentSideTag)
    this.setState({
      uploading: true,
      uploadModalVisible: false
    });
  }

  onAddressChange = (e) => {
    this.setState({ address: e.target.value })
  }
  onDetailChange = ({ value }) => {
    this.setState({ detail: value })
  }
  onDescChange = (e) => {
    this.setState({ desc: e.target.value })
  }
  searchForAddress = (address) => {
    console.log(address)
    this.props.searchAddress(address);


  }

  openCreatePageModal = () => {
    this.setState({ createPageModalVisible: true })
  }
  handleCancelCreatePageModal = () => {
    this.setState({ createPageModalVisible: false })
  }
  createPage = () => {
    if (this.state.createPageName) {
      this.props.createPage(this.state.createPageName, this.props.userInfo)
    }
    this.setState({ createPageModalVisible: false })


  }


  createCategory = () => {
    if (this.props.userInfo) {

      this.props.createCategory(this.props.userPageCat, this.state.currentTags, this.state.createCatName, this.props.userInfo)
      this.setState({ createCatModalVisible: false });
    }
  }
  handleCancelCreateCatModal = () => {
    this.setState({ createCatModalVisible: false });
  }

  openCreateCatModal = () => {
    this.setState({ createCatModalVisible: true })
  }

  setSideTags = (tags) => {
    this.setState({ currentSideTag: tags })
  }
  render() {
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    const imageUrl = this.state.imageUrl;

    const webSideMenu = (
      <Menu
        mode="inline"
        defaultSelectedKeys={['1']}

        style={{ height: '100%' }}
      >
        <SubMenu key="sub1" title={<span><Icon type="user" />Follow</span>}>

          {this.props.followPageList.map(item => <Menu.Item key={item.detail.pageUID}><Link to={"/followpage/" + item.detail.pageUID}>{item.name}</Link> </Menu.Item>)}
        </SubMenu>


        <Menu.Item key={"netshare"}> <a onClick={() => {
          this.props.getSharePostList(this.props.userInfo)
          this.setState({ isViewShare: true })
        }}>NetShare</a> </Menu.Item>

      </Menu>

    )


    return (
      <div className="UserPage">
        <Modal
          title="Upload"
          visible={this.state.uploadModalVisible}
          onOk={this.handleUpload}
          onCancel={this.handleCancel}
          width={900}
        >



          <Input.TextArea placeholder="Tiêu đề" autosize value={this.state.address} onChange={this.onAddressChange} />
          <Input.TextArea placeholder="Giới thiệu" autosize value={this.state.desc} onChange={this.onDescChange} />
          <RichTextEditor value={this.state.detail} onDetailChange={this.onDetailChange} />
          <UploadImageCustom ref="ImageUploader" />

        </Modal>

        <Modal
          title="Tạo trang"
          visible={this.state.createPageModalVisible}
          onOk={this.createPage}
          onCancel={this.handleCancelCreatePageModal}
        >
          <Input placeholder="Tên trang bạn muốn tạo" value={this.state.createPageName} onChange={(e) => this.setState({ createPageName: e.target.value })} ></Input>

        </Modal>

        <Modal
          title="Tạo mục"
          visible={this.state.createCatModalVisible}
          onOk={this.createCategory}
          onCancel={this.handleCancelCreateCatModal}
        >
          <Input placeholder="Tên mục bạn muốn tạo" value={this.state.createCatName} onChange={(e) => this.setState({ createCatName: e.target.value })} ></Input>

        </Modal>



        <Layout>

          <Header>
            <Link to="/"> <img style={{ width: 150, height: 50 }} src={logo}></img></Link>
            <UserHeader openCreateCatModal={this.openCreateCatModal} isUserPage={true} openCreatePageModal={this.openCreatePageModal} setTags={this.setTags} showModal={this.showModal} login={this.login} userInfo={this.props.userInfo} />
          </Header>
          {this.props.userPageInfo ?
            <div className="PageName">{this.props.userPageInfo.pageName}</div>
            : null}
          <Content>


            <Input.Search
              placeholder="Tìm kiếm"
              onSearch={value => this.searchForAddress(value)}
              style={{ width: 200 }}
            />
            <UserFooter currentTags={this.state.currentTags} setSubTags={this.setSubTags} />
          </Content>

          <Footer>



          </Footer>
          <Layout>


            <Layout>
              <Sider>
                {this.state.currentTags === "Home" ?
                  <div className="WebHotTagsSider">
                    {webSideMenu}
                  </div>

                  : null}
                {this.props.userPageCat ?
                  <HotTagsSider setSideTags={this.setSideTags} tagsList={this.props.userPageCat.catList[this.state.currentTags]} />
                  : null}

              </Sider>

              <Content className="ContentResList">
                {this.state.isViewShare ?
                  <ResultList currentSideTag="All" currentTags="All" currentSubTags="All"resultList={this.props.shareList} />
                  :
                  <ResultList showOnlyPostOfThisUser={true} currentSideTag={this.state.currentSideTag} currentTags={this.state.currentTags} currentSubTags={this.state.currentSubTags} resultList={this.props.resList} />
                }
              </Content>

            </Layout>
          </Layout>
        </Layout>
      </div>
    );
  }
}

function mapState2Props(state) {
  return {
    userInfo: state.accountReducer.userInfo,
    resList: state.accountReducer.resList,
    userPageInfo: state.accountReducer.userPageInfo,
    userPageCat: state.accountReducer.userPageCat,
    followPageList: state.accountReducer.followPageList,
    shareList: state.accountReducer.shareList


  };
}
const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    loginGG,
    checkLogged_and_Login_automatically,
    uploadAddressImage,
    searchAddress,
    createPage,
    getPageInfo,
    getCatUserPage,
    createCategory,
    getSharePostList

  }, dispatch)

}

export default withRouter(connect(mapState2Props, mapDispatchToProps)(UserPage));
